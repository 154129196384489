<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="./" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-whistle</v-icon>
      </v-col>
      <v-col align-self="center">
        <h2 class="white--text font-weight-light">SCHIEDSRICHTER ERFASSEN</h2>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="veranstaltung.data">
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.beginn.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="2"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        BIS
      </v-col>
      <v-col
        cols="4"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum != veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          {{ parseDate(veranstaltung.data.ende.datum) }}
          <br />
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }}
          </span>
        </h4>
      </v-col>
      <v-col
        cols="10"
        class="text-center"
        align-self="center"
        v-if="veranstaltung.data.beginn.datum == veranstaltung.data.ende.datum"
      >
        <h4 class="white--text font-weight-light">
          Am
          <span class="font-weight-bold">
            {{ parseDate(veranstaltung.data.beginn.datum) }}
          </span>
          von
          <span class="font-weight-bold">
            {{ veranstaltung.data.beginn.uhrzeit }}
          </span>
          bis
          <span class="font-weight-bold">
            {{ veranstaltung.data.ende.uhrzeit }} Uhr
          </span>
        </h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <h3 class="my-4 white--text" v-if="anwesende_schiris.length == 0">
                Für dieses Spiel wurden noch keine Schiedsrichter erfasst.
              </h3>
              <h3 class="my-4 white--text" v-if="anwesende_schiris.length > 0">
                Erfasste Schiedsrichter:
              </h3>
              <v-card
                v-for="gast in anwesende_schiris"
                :key="gast.id"
                class="my-1"
                color="success"
                @click="del(gast)"
              >
                <v-card-title>
                  <v-icon class="mr-4">
                    mdi-check-bold
                  </v-icon>
                  {{ gast.person.vorname }} {{ gast.person.name }}
                  <v-icon class="ml-4">
                    {{
                      gast.ggg
                        ? gast.ggg.genesen || gast.ggg.geimpft
                          ? 'mdi-shield-check'
                          : gast.ggg.getestet
                          ? 'mdi-eyedropper'
                          : ''
                        : ''
                    }}
                  </v-icon>
                </v-card-title>
              </v-card>
              <v-dialog v-model="delguest" persistent max-width="400">
                <v-card
                  :dark="!template.light"
                  :light="template.light"
                  v-if="del_guest"
                >
                  <v-card-title class="text-justify">
                    Möchtest Du den Schiedsrichter
                    <br />
                    {{ del_guest.person.vorname + ' ' + del_guest.person.name }}
                    wirklich entfernen?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="delguest = false">
                      Abbrechen
                    </v-btn>
                    <v-btn
                      color="success"
                      text
                      :loading="deleting"
                      @click="deleteGast(del_guest.id)"
                    >
                      Schiedsrichter entfernen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" class="pt-0 text-center">
              <cardbutton
                title="Schiedsrichter hinzufügen"
                :textcolor="template.colors.inline_primary_text"
                :bgcolor="template.colors.primary"
                upper
                @click="newtempmember = true"
              />
              <CreateSchiedsrichter
                v-if="newtempmember"
                v-on:closing="
                  newtempmember = false
                  aktualisiereListe()
                "
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      app
      timeout="4000"
      v-model="saved"
      multi-line
      color="success"
      centered
      rounded="rounded-xl"
      top
    >
      <h2>
        <v-icon large class="mr-2">mdi-calendar-sync</v-icon>
        Anwesenheitsliste aktualisiert
      </h2>
      <template v-slot:action="{ attrs }">
        <v-btn
          :dark="!template.light"
          icon
          v-bind="attrs"
          @click="saved = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import cardbutton from '../../../elements/cards/card-button'
import CreateSchiedsrichter from './createSchiedsrichter'
import router from '../../../routes'

export default {
  name: 'Schiedsrichter-Anwesenheit-bearbeiten',
  data() {
    return {
      member: [],
      anwesende_schiris: [],
      newmember: false,
      newtempmember: false,
      delguest: false,
      del_guest: '',
      deleting: false,
      loading: false,
      saved: false,
      sort: 'vorname',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  components: {
    CreateSchiedsrichter,
    cardbutton,
  },
  mounted() {
    if (this.$route.params.veranstaltung) {
      if (this.veranstaltung.id != this.$route.params.veranstaltung) {
        store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      }
      store.dispatch('setTeam', this.veranstaltung.data.mannschaft)
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('veranstaltung.id', '==', this.veranstaltung.id)
        .onSnapshot((snap) => {
          var index
          this.anwesende_schiris = []
          snap.forEach((doc) => {
            if (doc.data().aktivitaet == 'Schiedsrichter') {
              this.anwesende_schiris.push(doc.data())
              index = this.anwesende_schiris.length - 1
              this.anwesende_schiris[index].id = doc.id
            }
          })
        })
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    change_sort() {
      if (this.sort == 'vorname') {
        this.sort = 'name'
      } else {
        this.sort = 'vorname'
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.veranstaltung.data.mannschaft)
        .collection('Mitglieder')
        .orderBy(this.sort)
        .onSnapshot((querySnap) => {
          this.member = []
          querySnap.forEach((doc) => {
            this.member.push(doc.data())
            var index = this.member.length - 1
            this.member[index].id = doc.id
          })
          this.aktualisiereListe()
        })
    },
    del(gast) {
      this.delguest = true
      this.del_guest = gast
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    deleteGast(id) {
      this.deleting = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .doc(id)
        .delete()
        .then(() => {
          this.aktualisiereListe()
          this.deleting = false
          this.delguest = false
          this.del_guest = ''
        })
    },
    aktualisiereListe() {
      var i
      this.anwesende_schiris = []
      var liste = this.veranstaltung.anwesenheit
      for (i = 0; i < liste.length; i++) {
        if (liste[i].aktivitaet == 'Schiedsrichter') {
          this.anwesende_schiris.push(liste[i])
        }
      }
    },
  },
}
</script>
