<template>
  <v-dialog
    value="true"
    :content-class="$vuetify.breakpoint.mdAndDown ? '' : 'rounded-xl'"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>Schiedsrichter hinzufügen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4">
          <v-col cols="10" class="px-2 pb-0">
            <v-text-field
              v-model="t.vorname"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Vorname"
            ></v-text-field>
            <v-text-field
              v-model="t.name"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Nachname"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.adresse"
              :rules="[rules.required, rules.street, rules.streetnr]"
              filled
              rounded
              label="Straße + Nr."
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="px-2 py-0">
            <v-text-field
              v-model="t.plz"
              :rules="[rules.required, rules.plz]"
              filled
              rounded
              label="Postleitzahl"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="px-2 py-0">
            <v-text-field
              v-model="t.ort"
              :rules="[rules.required, rules.name]"
              filled
              rounded
              label="Ort"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2 py-0">
            <v-text-field
              v-model="t.telefon"
              :rules="[rules.required, rules.telefon]"
              filled
              rounded
              label="Telefon-/Mobilnummer"
            ></v-text-field>
          </v-col>
          <v-col cols="10" class="px-2">
            <ggswitches
              :geimpft="false"
              :getestet="false"
              :genesen="false"
              :datum="false"
              @valid="validate_ggg($event)"
              @change="get_ggg_data($event)"
            ></ggswitches>
          </v-col>
          <v-col cols="10" class="px-2">
            <v-btn
              class="my-4"
              block
              large
              :disabled="!ggg_valid"
              rounded
              :color="template.colors.primary"
              @click="create()"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Hinzufügen
            </v-btn>
            <v-btn
              class="my-4"
              block
              large
              rounded
              outlined
              :color="template.colors.primary"
              @click="closing()"
            >
              Abbrechen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import { mapGetters } from 'vuex'
import ggswitches from '../2G-Regel/switches.vue'

export default {
  name: 'Spieler-erstellen',
  components: {
    ggswitches,
  },
  data() {
    return {
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
      },
      valid: false,
      ggg_valid: false,
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      team: 'team',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    create() {
      if (this.$refs.form.validate()) {
        var event = this.veranstaltung.data
        event.id = this.veranstaltung.id
        var zutritt = new Zeit(
          this.veranstaltung.data.beginn.datum,
          this.veranstaltung.data.beginn.uhrzeit,
        )
        var verlassen = new Zeit(
          this.veranstaltung.data.ende.datum,
          this.veranstaltung.data.ende.uhrzeit,
        )

        var daten = {
          veranstaltung: event,
          aktivitaet: 'Schiedsrichter',
          sportstaette: {
            name: this.veranstaltung.data.sportstaette
              ? this.veranstaltung.data.sportstaette
              : '',
          },
          person: {
            vorname: this.t.vorname.trim(),
            name: this.t.name.trim(),
            adresse: this.t.adresse.trim(),
            plz: this.t.plz.trim(),
            ort: this.t.ort.trim(),
            telefon: this.t.telefon.trim(),
          },
          zutritt: {
            datum: zutritt.getDatum(),
            uhrzeit: zutritt.getUhrzeit(),
            timestamp: zutritt.getTimestamp(),
          },
          verlassen: {
            datum: verlassen.getDatum(),
            uhrzeit: verlassen.getUhrzeit(),
            timestamp: verlassen.getTimestamp(),
          },
          ggg: this.ggg,
        }
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Anwesenheit')
          .add(daten)
          .catch((error) => {
            console.log(error)
          })
          .then((doc) => {
            this.closing()
          })
      }
    },
  },
}
</script>
